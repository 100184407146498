import React, { useState } from "react";
import { Fab, Drawer, Box, Typography, Button, Card, CardContent, Divider } from "@mui/material";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import PhoneIcon from "@mui/icons-material/Phone";
import SupportIcon from "@mui/icons-material/Support";
import { green, grey } from "@mui/material/colors";

const FaleConosco: React.FC = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (state: boolean) => () => {
    setOpen(state);
  };

  const dias = [
    "Domingo - Fechado",
    "Segunda - 08:00 até 18:00",
    "Terça   - 08:00 até 18:00",
    "Quarta  - 08:00 até 18:00",
    "Quinta  - 08:00 até 18:00",
    "Sexta   - 08:00 até 18:00",
    "Sábado  - Fechado",
  ];

  const hoje = new Date().getDay();

  return (
    <>
    
      <Fab
        color="primary"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", 
        }}
        onClick={toggleDrawer(true)}
      >
        <HeadsetMicIcon />
      </Fab>

    
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            padding: '16px',
            width: 380,
            borderRadius: '10px 0 0 10px',
            boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.15)',
            marginTop: '70px',
          },
        }}
      >
        <Box sx={{ mt: 4 }}>

         
          <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
            <Typography variant="h5" color="primary" fontWeight="bold">
              Fale Conosco
            </Typography>
          </Box>

       
          <Card sx={{ mb: 3, boxShadow: 3, borderRadius: 3 }}>
            <CardContent>
              <Typography variant="h6" color="text.primary" fontWeight="bold" mb={2}>
                Horário de Atendimento
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {dias.map((dia, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: index === hoje ? "bold" : "normal",
                    color: index === hoje ? green[500] : grey[900],
                    mb: 1,
                  }}
                >
                  <Typography variant="body2">{dia}</Typography>
                </Box>
              ))}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3, boxShadow: 3, borderRadius: 3 }}>
            <CardContent>
              <Typography variant="h6" color="text.primary" fontWeight="bold" mb={2}>
                Abra seu Chamado
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Button
                variant="text"
                href="https://tanis.movidesk.com/"
                target="_blank"
                sx={{
                  color: "primary.main",
                  textDecoration: "underline",
                  "&:hover": { color: "primary.dark" },
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SupportIcon sx={{ mr: 1 }} />
                Suporte Tanis
              </Button>
            </CardContent>
          </Card>

     
          <Card sx={{ mb: 3, boxShadow: 3, borderRadius: 3 }}>
            <CardContent>
              <Typography variant="h6" color="text.primary" fontWeight="bold" mb={2}>
                Telefone de Contato
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="body2" display="flex" alignItems="center">
                  <PhoneIcon sx={{ mr: 1 }} /> Suporte Técnico: <strong>21 3497-4383</strong>
                </Typography>
                <Typography variant="body2" display="flex" alignItems="center">
                  <PhoneIcon sx={{ mr: 1 }} /> Financeiro: <strong>21 99606-0852</strong>
                </Typography>
              </Box>
            </CardContent>
          </Card>

        
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 2,
              borderRadius: 5,
              fontWeight: "bold",
              padding: "12px 0",
              "&:hover": {
                backgroundColor: "#1976d2",
              },
            }}
            onClick={toggleDrawer(false)}
          >
            Fechar
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default FaleConosco;
