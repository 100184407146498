const urlDBWEB = "https://api-dbweb-tanis.fwc.cloud:8084/api/dbweb/v10";
const urlBase = "https://dev-login-api.tanis-hub.com.br/api/hublogin/v10";
const urlCadastro = "https://dev-admin-api.tanis-hub.com.br/api/hubadmin/v10";
const urlCEP = "https://dev-cep-api.tanis-hub.com.br/api/hubcep/v10";


export const environment = {
  urlLogin: urlBase + "/Usuarios/login",
  urlTokenDBWEB: urlDBWEB + "/auth/gettoken",
  urlBuscaCnpj: urlDBWEB + "/Cnpj/DadosCNPJ/ReceitaWS/",
  urlBuscaCnpjTanis: urlDBWEB + "/Empsis/info/",
  urlRegistroUser: urlBase + "/Usuarios/registrar",
  urlConfirmaEmail: urlBase + "/Usuarios/confirmar-email/",
  urlReenviaEmail: urlBase + "/Usuarios/reenvia-confirmacao-email/",
  urlResetSenha: urlBase + "/Usuarios/reset-senha/",
  urlTrocarSenha: urlBase + "/Usuarios/troca-senha/",
  urlCadastro: urlCadastro + "/Cadastro",
  urlAssociar: urlCadastro + "/Cadastro/Associar",
  urlCadDocumento: urlCadastro + "/CadastroDocumento",
  urlCadContato: urlCadastro + "/CadastroContato",
  urlCadEndereco: urlCadastro + "/CadastroEndereco",
  urlBuscaCep: urlCEP + "/Cep",
  urlTipoContato: urlCadastro + "/TipoContato",
  urlCadastroContato: urlCadastro + "/CadastroContato",
  urlCadastroEndereco:urlCadastro +"/CadastroEndereco",
};

export default environment;
