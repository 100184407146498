import React, { useState, useEffect } from "react";
import {
  IconButton,
  Badge,
  Popover,
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ReportProblem, Message, Update } from "@mui/icons-material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/pt-br";
import { getCadastroUser } from "../services/Login.Service";
import { getCookie } from "../services/Utils.Service";

interface Notification {
  id: number;
  type: string;
  message: string;
  time: string;
}

dayjs.extend(relativeTime);
dayjs.locale("pt-br");

const NotificationButton: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    verifica();
    },[])

  const verifica = async () => {
    const cad = await getCadastroUser(getCookie("idCad"));
    if (
      cad.data_nascimento === "" ||
      cad.data_nascimento === "" ||
      !cad.contatos ||
      cad.contatos.length === 0 ||
      !cad.enderecos ||
      cad.enderecos.length === 0
    ) {
        addNotification("Complete o seu Perfil!", "alert")   
    }
  };

  const addNotification = (message: string, type: string) => {
    setNotifications((prev) => [
      ...prev,
      {
        id: prev.length > 0 ? prev[prev.length - 1].id + 1 : 1,
        message,
        type,
        time: dayjs().format("HH:mm"),
      },
    ]);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  const renderNotificationIcon = (type: string) => {
    switch (type) {
      case "update":
        return <Update />;
      case "message":
        return <Message />;
      case "alert":
        return <ReportProblem />;
      default:
        return <NotificationsIcon />;
    }
  };

  const getTimeElapsed = (time: string) => {
    const now = dayjs();
    const todayTime = dayjs(`${now.format("YYYY-MM-DD")}T${time}:00`);

    return todayTime.fromNow();
  };

  return (
    <>
      <IconButton color="primary" onClick={handleNotificationClick}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "10px", maxWidth: "320px", width: "300px" }}>
          <Typography variant="h6" gutterBottom>
            Notificações
          </Typography>
          <Divider />
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <Card key={notification.id} style={{ marginBottom: "10px" }}>
                <CardContent style={{ display: "flex", alignItems: "center" }}>
                  {renderNotificationIcon(notification.type)}
                  <div style={{ marginLeft: "10px" }}>
                    <Typography variant="body2">
                      {notification.message}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      <small>{getTimeElapsed(notification.time)}</small>
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Você não tem novas notificações.
            </Typography>
          )}
          <Button
            onClick={clearNotifications}
            color="primary"
            fullWidth
            style={{ marginTop: "10px" }}
          >
            Limpar todas
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default NotificationButton;
