export default class EnderecoModel {
  cod_logradouro: string;
  cep: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  uf: string;
  pais: string;

  constructor(
    cod_logradouro: string = "",
    cep: string = "",
    logradouro: string = "",
    bairro: string = "",
    cidade: string = "",
    uf: string = "",
    pais: string = ""
  ) {
    this.cod_logradouro = cod_logradouro;
    this.cep = cep;
    this.logradouro = logradouro;
    this.cod_logradouro = cod_logradouro;
    this.bairro = bairro;
    this.cidade = cidade;
    this.uf = uf;
    this.pais = pais;
  }
}
