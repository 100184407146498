import React, { useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import * as CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import LoginModel from "../../models/login.model";
import CadCadastroModel from "../../models/cadcadastro.model";
import caddocumento from "../../models/caddocumento.model";
import { login } from "../../services/Auth/Auth.Service";
import { postCadastro, postDocumento, getCadLogin } from "../../services/Login.Service";
import { useNotification } from "../../components/notification";
import lg_tanishub from "../../assets/lg_tanishub.png";
import "./Login.scss";
import { getCookie } from "../../services/Utils.Service";

const Login = () => {
  // Definindo o estado com o modelo LoginModel
  const [formlogin, setFormlogin] = useState<LoginModel>(new LoginModel());
  const [vpassword, setPassword] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [cadastro, setcadastro] = useState<CadCadastroModel>(
    new CadCadastroModel()
  );
  const [caddoc, setcaddoc] = useState<caddocumento>(new caddocumento());

  const navigate = useNavigate();
  const { showNotification } = useNotification(); // Usando o hook corretamente para obter a função showNotification

  // Função para validar o e-mail
  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Função para tratar a mudança no campo de e-mail
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setIsEmailValid(validateEmail(emailValue));
    // Atualizando o estado do formlogin com o e-mail
    setFormlogin(
      new LoginModel(
        emailValue,
        formlogin.password,
        formlogin.cnpj,
        formlogin.sistema
      )
    );
  };

  // Função para tratar a mudança no campo de senha
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    // Atualizando o estado do formlogin com a senha criptografada
    const hashpassword = CryptoJS.SHA256(passwordValue).toString();
    setFormlogin(
      new LoginModel(
        formlogin.login,
        hashpassword,
        formlogin.cnpj,
        formlogin.sistema
      )
    );
  };

  // Função para realizar o login
  const handleSubmit = async () => {
    showNotification("Realizando Login", "warning"); // Usando o método showNotification
    const result = await login(formlogin);
    if (result.success) {
      const verificacad = await getCadLogin(getCookie("idCad"));      
      if (verificacad !== 200 || "Cadastro não encontrado") {        
        const novoCadastro = {
          ...cadastro,
          id_cadastro:getCookie("idCad"),
          nome: getCookie("username"),
          tipo_pessoa: "Fisica",
          fantasia: getCookie("username"),
          nascimento: null,
        };        
        setcadastro(novoCadastro);        

        const res = await postCadastro(novoCadastro);

        if (res.status === 200) {
          const idCadastro = res.data.info.dados.id_cadastro;
          const novoDoc = {
            ...caddoc,
            id_cadastro: idCadastro,
            tipo_documento: {
              ...caddoc.tipo_documento,
              cod_tipo_documento: 1,
              ds_tipo_documento: "CPF",
              tipo_pessoa: "Fisica",
            },
            ds_documento:String(result.doc),
          };
          setcaddoc(novoDoc);
          await postDocumento(novoDoc);
        }
        navigate("/home");
        showNotification("Login efetuado", "success");
      } else {
        navigate("/home");
        showNotification("Login efetuado", "success");
      }
    } else {
      showNotification(result.error, "error");
    }
  };

  return (
    <div className="contenier-login">
      <div className="card2">
        <div>
          <div>
            <img
              src={lg_tanishub}
              alt="Logo da Empresa"
              style={{ width: "120px", height: "100px", marginRight: "10px" }}
            />
          </div>
          <h2>Iniciar Sessão</h2>
          <form>
            <div className="input-container">
              <TextField
                id="login"
                label="Digite seu E-mail"
                variant="outlined"
                value={formlogin.login}
                onChange={handleEmailChange}
                className="input-field"
              />
            </div>

            {isEmailValid && (
              <div className="input-container">
                <TextField
                  id="password"
                  label="Digite sua Senha"
                  value={vpassword}
                  type="password"
                  onChange={handlePasswordChange}
                  className="input-field"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <ArrowCircleRightOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}

            <div className="links">
              <span className="link" onClick={() => navigate("/signup")}>
                Criar conta
              </span>
              <span
                className="link"
                onClick={() => navigate("/forgot-password")}
              >
                Esqueci minha senha
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
