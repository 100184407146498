import React from "react";
import { BrowserRouter as Router } from "react-router-dom"; // Importa o Router
import RoutesApp from "./routes";
import GlobalStyle from "./styles/GlobalStyle";
import AutoLogout from "./services/AutoLogout.Service";
import FaleConosco from "./components/faleconosco";

function App() {
  return (
    <Router> {}
      <AutoLogout />
      <GlobalStyle />
      <RoutesApp />
      <FaleConosco />
    </Router>
  );
}

export default App;
