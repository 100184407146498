export default class CadEnderecoModel {
  cod_cad_endereco: number;
  id_cadastro: string;
  tipo_endereco: string;
  cod_logradouro: number;
  numero_endereco: string;
  numero_complemento: string;
  

  constructor(
    cod_cad_endereco: number = 0,
    id_cadastro: string = "",
    tipo_endereco: string = "Principal", //Principal, Faturamento, Entrega
    cod_logradouro: number = 0,
    numero_endereco: string = "",
    numero_complemento: string = ""  
  ) {
    this.cod_cad_endereco = cod_cad_endereco;
    this.id_cadastro = id_cadastro;
    this.tipo_endereco = tipo_endereco;
    this.cod_logradouro = cod_logradouro;
    this.numero_endereco = numero_endereco;
    this.numero_complemento = numero_complemento;
  }
}
