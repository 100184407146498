import { createGlobalStyle } from "styled-components";
import bg_login from "../assets/bg_theme_light.png";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800;900&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'M PLUS 1p', system-ui, sans-serif; 
  }

  html, body, #root {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden; /* Evita a barra lateral */
  }

  body {
    background-image: url(${bg_login});
    background-size: cover; /* Faz a imagem cobrir toda a área */
    background-position: center; /* Centraliza a imagem */
    background-repeat: no-repeat; /* Impede que a imagem se repita */
    min-height: 100vh; /* Garante que o fundo ocupe pelo menos 100% da altura da tela */
  }
`;

export default GlobalStyle;
