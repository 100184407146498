export default class CadContatoModel {
  id_cadastro: string;
  cod_contato: number;
  tipo_contato: {
    cod_tipo_contato: number;
    ds_tipo_contato: string;
  };
  ds_contato: string;

  constructor(
    id_cadastro: string = "",
    cod_contato: number = 0,
    tipo_contato: { cod_tipo_contato: number; ds_tipo_contato: string } = {
      cod_tipo_contato: 0,
      ds_tipo_contato: "",
    },
    ds_contato: string = ""
  ) {
    this.id_cadastro = id_cadastro;
    this.cod_contato = cod_contato;
    this.tipo_contato = tipo_contato;
    this.ds_contato = ds_contato;
  }
}
