interface TipoContato {
  cod_tipo_contato: number;
  ds_tipo_contato: string;
}

interface Contato {
  id_cadastro: string;
  cod_contato: number;
  tipo_contato: TipoContato;
  ds_contato: string;
}

interface TipoDocumento {
  cod_tipo_documento: number;
  ds_tipo_documento: string;
  tipo_pessoa: string;
}

interface Documento {
  id_cadastro: string;
  cod_documento: number;
  tipo_documento: TipoDocumento;
  ds_documento: string;
}

interface Perfil {
  cod_aplicacao: number;
  nome_aplicacao: string;
  id_perfil: string;
  ds_perfil: string;
}

interface Associacao {
  codigo: number;
  idCadastro: string;
  status: string;
  nome: string;
  associadoEm: string;
}

interface Logradouro {
  cod_logradouro: number;
  cep: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  uf: string;
  cod_ibge: number;
  ddd: string;
  cod_pais_sped: number;
  pais: string;
}

interface Endereco {
  id_cadastro: string;
  cod_cad_endereco: number;
  tipo_endereco: string;
  cod_logradouro: number;
  logradouro: Logradouro;
  numero_endereco: string;
  numero_complemento: string;
}

export default class CadastroModel {
  cod_cadastro: number;
  id_cadastro: string;
  status: string;
  tipo_pessoa: string;
  nome: string;
  fantasia: string;
  estrangeiro: string;
  data_nascimento?: string | null;
  data_criacao: string;
  data_atualizacao: string;
  contatos: Contato[];
  documentos: Documento[];
  enderecos: Endereco[];
  perfil: Perfil[];
  associacoes: Associacao[];

  constructor(
    cod_cadastro: number = 0,
    id_cadastro: string = "",
    status: string = "Ativo",
    tipo_pessoa: string = "Fisica",
    nome: string = "",
    fantasia: string = "",
    estrangeiro: string = "N",
    data_nascimento: string | null = null,
    data_criacao: string = "",
    data_atualizacao: string = "",
    contatos: Contato[] = [],
    documentos: Documento[] = [],
    enderecos: Endereco[] = [],
    perfil: Perfil[] = [],
    associacoes: Associacao[] = []
  ) {
    this.cod_cadastro = cod_cadastro;
    this.id_cadastro = id_cadastro;
    this.status = status;
    this.tipo_pessoa = tipo_pessoa;
    this.nome = nome;
    this.fantasia = fantasia;
    this.estrangeiro = estrangeiro;
    this.data_nascimento = data_nascimento;
    this.data_criacao = data_criacao;
    this.data_atualizacao = data_atualizacao;
    this.contatos = contatos;
    this.documentos = documentos;
    this.enderecos = enderecos;
    this.perfil = perfil;
    this.associacoes = associacoes;
  }
}
