import api from "./Auth/Inteceptor";
import environment from "../environments/environments";
import SingupModel from "../models/signup.model";
import ResetSenhaModel from "../models/resetsenha.model";
import CadCadastroModel from "../models/cadcadastro.model";
import caddocumento from "../models/caddocumento.model";
import CadEnderecoModel from "../models/cadendereco.model";
import CadContatoModel from "../models/cadcontato.model"
import { getCookie } from "./Utils.Service";


const urlRegistroUser = environment.urlRegistroUser;
const urlConfirmaEmail = environment.urlConfirmaEmail;
const urlReenviaEmail = environment.urlReenviaEmail;
const urlResetSenha = environment.urlResetSenha;
const urlTrocarSenha = environment.urlTrocarSenha;
const urlCadastro = environment.urlCadastro;
const urlCadDocumento = environment.urlCadDocumento;
const urlCadEndereco = environment.urlCadEndereco;
const urlAssociar = environment.urlAssociar;
const urlBuscaCep = environment.urlBuscaCep;
const urlTipoContato: string = environment.urlTipoContato;
const urlCadastroContato: string = environment.urlCadastroContato;
const urlCadastroEndereco: string = environment.urlCadastroEndereco;

export const PostSignUp = async (formData: SingupModel) => {
  try {
    await api.post(`${urlRegistroUser}`, formData);
    return { success: true };
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.info?.dados?.descricao || "Erro desconhecido";
    return { success: false, error: errorMessage };
  }
};

export const ReenvioToken = async (email: string) => {
  try {
    await api.get(`${urlReenviaEmail}${email}`);
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const ConfirmarEmail = async (token: string | undefined) => {
  try {
    await api.post(`${urlConfirmaEmail}${token}`, "");
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const ResetSenha = async (email: string) => {
  try {
    await api.get(`${urlResetSenha}${email}`);
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const TrocaSenha = async (token: string | undefined, body: ResetSenhaModel) => {
  try {
    await api.post(`${urlTrocarSenha}${token}`, body);
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const getCadastro = async (cnpj: string) => {
  try {
    const response = await api.get(`${urlCadastro}?cpf_cnpj=${cnpj}`);
    return response;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const getCadastroUser = async (id: string) => {
  try {
    const response = await api.get(`${urlCadastro}/${id}`);
    return response.data.info.dados;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const getCadLogin = async (id: string) => {
  try {
    const response = await api.get(`${urlCadastro}/${id}`);
    return response.status;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const postCadastro = async (contato: CadCadastroModel) => {
  try {
    const response = await api.post(`${urlCadastro}`, contato);
    return response;
  } catch (error: any) {    
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const putCadastro = async (contato: CadCadastroModel) => {
  try {
    const response = await api.put(`${urlCadastro}`, contato);
    return response.status;
  } catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};

export const putAssociar = async (idpj:string) => {
  const json = {
    id_cad_pj: idpj,
    id_cad_pf: getCookie("idCad"),
    associar: "Incluir",
  };
  try {
    const response = await api.put(`${urlAssociar}`, json);
    return response.status;  
  } catch (error: any) {
      console.error("Erro ao mandar Modulo:", error.response.data.info?.dados?.descricao || error);
    ;
  }
};

export const postDocumento = async (documento: caddocumento) => {
  try {
    const response = await api.post(`${urlCadDocumento}`, documento);
    return response;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};


export const postEndereco = async (endereco: CadEnderecoModel) => {
  try {
    const response = await api.post(`${urlCadEndereco}`, endereco);
    return response;
  } catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};

export const putEndereco = async (endereco: any) => {
  try {
    const response = await api.put(`${urlCadEndereco}`, endereco); // Envia o objeto atualizado
    return response.data.info; // Retorna a resposta de sucesso ou falha
  }catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};

export const deleteEndereco = async (id: string) => {
  try {
    const response = await api.delete(`${urlCadEndereco}?cod_cad_endereco=${id}`);
    console.log("Resposta da exclusão:", response.data);  // Log detalhado da resposta da API
    if (response.data && response.data.info) {
      return response.data.info; // Retorna a mensagem de sucesso ou falha
    } else {
      return "Erro ao excluir o endereço"; // Retorna uma mensagem de erro padrão
    }
  } catch (error: any) {
    console.error("Erro ao excluir o endereço:", error);  // Log detalhado do erro
    return error.response?.data?.info?.dados?.descricao || "Erro desconhecido";
  }
};

export const getCEP = async (CEP: string) => {
  try {
    const response = await api.get(`${urlBuscaCep}/${CEP}`);
    return response.data.info.dados;
  } catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};



export const getTipoContato = async () => {
  try {
    const response = await api.get(`${urlTipoContato}`);
    return response.data.info.dados;
  } catch (error: any) {
    console.error("Erro ao buscar Tipos contato:", error?.message || error);
    throw new Error(
      error?.message || "Erro desconhecido ao buscar o SubModulo."
    );
  }
};




export const getCadContato = async (id: string) => {
  try {
    const response = await api.get(`${urlCadastroContato}${id}`);
    return response;
  } catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};

export const postCadContato = async (contato: CadContatoModel) => {
  try {
    const response = await api.post(`${urlCadastroContato}`, contato);
    return response;
  } catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};

export const putCadContato = async (contato: CadContatoModel) => {
  try {
    const response = await api.put(`${urlCadastroContato}`, contato);
    return response;
  } catch (error: any) {
    return error.response.data.info?.dados?.descricao || error?.message;
  }
};

export const deleteCadContato = async (contato: string) => {
  try {
    // Monta a URL com o parâmetro cod_cad_contato na query
    const response = await api.delete(`${urlCadastroContato}?cod_cad_contato=${contato}`);
    return response;
  } catch (error: any) {
    console.error("Erro na função deleteCadContato:", error);
    throw error.response?.data?.info?.dados?.descricao || error?.message;
  }
};


