import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Modal,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Toolbar,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Switch,
  InputAdornment,
  Select,
  SelectChangeEvent,
  InputLabel,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import InputMask from "react-input-mask";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import bg_theme_light from "../../assets/bg_theme_light.png";
import bg_theme_dark from "../../assets/bg_theme_dark.png";
import lg_theme_light from "../../assets/lg_theme_light.svg";
import lg_theme_dark from "../../assets/lg_theme_dark.svg";
import logo from "../../assets/logo.png";
import lg_contato from "../../assets/lg_contato.svg";
import lg_galago from "../../assets/lg_galago.svg";
import { logout, setCookie } from "../../services/Auth/Auth.Service";
import CadastroModel from "../../models/cadastro.model";
import CadCadastroModel from "../../models/cadcadastro.model";
import CadEnderecoModel from "../../models/cadendereco.model";
import CadContatoModel from "../../models/cadcontato.model";
import EnderecoModel from "../../models/endereco.model";
import {
  getCookie,
  getCadastroAssociacoes,
} from "../../services/Utils.Service";
import {
  getCEP,
  getCadastroUser,
  putEndereco,
  putCadastro,
  getCadastro,
  postEndereco,
  postCadContato,
  getTipoContato,
  deleteCadContato,
  deleteEndereco,
} from "../../services/Login.Service";
import {
  Sidebar,
  Menu as ProMenu,
  MenuItem as ProMenuItem,
} from "react-pro-sidebar";
import "./Home.scss";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../components/notification";
import NotificationButton from "../../components/notificationbutton";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#000a0a" },
    secondary: { main: "#4d5bff" },
    text: { primary: "#000", secondary: "#555" },
    background: { default: "#ffffff" },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#ffffff" },
    secondary: { main: "#000A1F" },
    text: { primary: "#ffffff", secondary: "#e0e0e0" },
    background: { default: "#121212" },
  },
});

const CardApp = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => setIsExpanded(!isExpanded);

  return (
    <div
    className="card-app"
    style={{
      background: isDarkMode
        ? "rgba(255, 255, 255, 0.1)" // Tema escuro: Fundo transparente escuro
        : "rgba(255, 255, 255, 0.2)", // Tema claro: Fundo transparente
      boxShadow: isDarkMode
        ? "0 6px 15px rgba(0, 0, 0, 0.5)" // Sombra mais forte no modo escuro
        : "0 6px 15px rgba(0, 0, 0, 0.1)", // Sombra mais suave no modo claro
      borderRadius: "12px", // Borda arredondada
      padding: "20px",
      color: isDarkMode ? "#f9fafa" : "#333", // Cor do texto de acordo com o tema
      transition: "all 0.3s ease", // Transição suave para todas as propriedades
      width: "400px", // Largura ajustada para melhorar o layout
      height: "auto", // Altura ajustada para o conteúdo
    }}
  >
    <a
      href="https://link-do-seu-aplicativo.com"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: "none", // Remove o sublinhado do link
        color: isDarkMode ? "#f9fafa" : "#333", // Cor do link conforme o tema
      }}
    >
      <img src={logo} alt="Logo" className="logo" />
      <span
        className="app-name"
        style={{
          fontSize: "1.5rem", // Aumenta o tamanho da fonte do nome
          fontWeight: "bold",
          color: isDarkMode ? "#f9fafa" : "#333", // Cor do nome do aplicativo
          transition: "color 0.3s ease", // Transição suave para a cor
          display: "block", // Garante que o nome ocupe toda a linha
          marginTop: "5px", // Espaço entre o nome e a descrição
        }}
      >
        Mercantis
      </span>
    </a>
    <div className="descricao">
      <p
        style={{
          color: isDarkMode ? "#f9fafa" : "#333", // Cor do texto conforme o tema
          fontSize: "1rem", // Aumenta o tamanho da fonte da descrição
          lineHeight: "1.6", // Ajusta o espaçamento entre linhas para melhor leitura
          marginTop: "20px", // Espaço entre o nome e a descrição
        }}
      >
        <strong>Descrição:</strong>
        <br />
        {isExpanded
          ? "O Software ERP Mercantis facilita o fluxo de informações de uma organização com objetivo de otimizar a tomada de decisões dentro da empresa. Portanto, o nosso sistema oferece as melhores soluções para uma gestão de alto desempenho focada nos Distribuidores Hospitalares. Tecnologia e inovação fazem parte do nosso DNA."
          : "O Software ERP Mercantis facilita o fluxo de informações de uma organização... "}
        <span
          onClick={toggleDescription}
          style={{
            color: "blue",
            cursor: "pointer",
            fontWeight: "600",
            transition: "color 0.3s ease", // Transição suave para a cor de interação
          }}
        >
          {isExpanded ? "Ler menos" : "Ler mais"}
        </span>
      </p>
    </div>
  </div>
  

  );
};

const Home = () => {
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 7,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#000a1f",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#000a1f",
      width: 30,
      height: 30,
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#cccaca",
      borderRadius: 20 / 2,
    },
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [menuButton, setmenuButton] = useState<HTMLElement | null>(null);
  const [usuario, setusuario] = useState<string>("");
  const [empresa, setEmpresa] = useState<string | null>("");
  const [selectEmp, setSelectEmp] = useState<boolean>(false);
  const [empresas, setempresas] = useState<any[]>([]);
  const [abrirModalEditar, setAbrirModalEditar] = useState(false);
  const [passoAtivo, setPassoAtivo] = useState(0);
  const [cadastro, setCadastro] = useState<CadastroModel>(new CadastroModel());
  const [cadcadastro, setcadCadastro] = useState<CadCadastroModel>(
    new CadCadastroModel()
  );
  const [cadendereco, setcadEndereco] = useState<CadEnderecoModel>(
    new CadEnderecoModel()
  );
  const [endereco, setEndereco] = useState<EnderecoModel>(new EnderecoModel());
  const [newendereco, setNewEndereco] = useState<EnderecoModel>(
    new EnderecoModel()
  );
  const [abrirnovoend, setAbrirNovoEnd] = useState(false);
  const [numend, setnumend] = useState<string>("");
  const [numcomp, setnumcomp] = useState("");
  const passos = ["Dados Pessoais", "Endereço", "Contato", "Empresas"];
  const navigate = useNavigate();
  const [contatos, setContatos] = useState<any[]>([]);
  const [cadcontato, setcadContato] = useState<CadContatoModel>(
    new CadContatoModel()
  );
  const [tiposContato, setTiposContato] = useState<any[]>([]);
  const [tipoSelecionado, setTipoSelecionado] = useState<string>("");
  const [contato, setContato] = useState<string>("");
  const [valorContato, setValorContato] = useState<string>("");
  const { showNotification } = useNotification();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const toggleTheme = () => setIsDarkMode(!isDarkMode);
  const toggleside = () => setIsOpen(!isOpen);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setmenuButton(event.currentTarget);
  };
  const handleClose = () => setmenuButton(null);

  const logo = isDarkMode ? lg_theme_dark : lg_theme_light;

  const currentHour = (): string => {
    const hours = new Date().getHours();
    if (hours >= 6 && hours < 12) return "Bom dia";
    if (hours >= 12 && hours < 18) return "Boa tarde";
    return "Boa noite";
  };

  // Removendo repetição entre as funções de selecionar tipo de contato
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setTipoSelecionado(event.target.value);
    setContato("");
  };


  const handleDeleteContato = async (idContato: string | number) => {
    try {
      const contatoIdStr = String(idContato);
      console.log("Tentando excluir o contato com id:", contatoIdStr);

      const response = await deleteCadContato(contatoIdStr);
      console.log("Resposta da exclusão:", response);

      // Verifica se response é um objeto (e não uma string de erro)
      if (
        response &&
        typeof response === "object" &&
        (response.status === 200 || response.status === 204)
      ) {
        setContatos((prevContatos) =>
          prevContatos.filter(
            (contato) => contato.cod_contato !== Number(contatoIdStr)
          )
        );
      } else {
        console.error(
          "Erro ao excluir o contato. Resposta inesperada:",
          response
        );
      }
    } catch (error) {
      console.error("Erro ao excluir o contato:", error);
    }
  };

  const adicionarContato = async () => {
    const tipoSelecionadoNumerico = Number(tipoSelecionado);
  
    // Verifica se o tipo selecionado é válido
    if (isNaN(tipoSelecionadoNumerico)) {
      alert("Tipo de contato inválido!");
      return;
    }
  
    const novoContato = new CadContatoModel(
      cadastro.id_cadastro,
      0,
      {
        cod_tipo_contato: tipoSelecionadoNumerico,
        ds_tipo_contato:
          tiposContato.find(
            (tipo) => tipo.cod_tipo_contato === tipoSelecionadoNumerico
          )?.ds_tipo_contato || "",
      },
      valorContato
    );
  
    // Atualiza o estado com o novo contato
    setcadContato(novoContato);
  
    try {
      // Envia o novo contato para a API
      const response = await postCadContato(novoContato);
  
      // Verificando se a resposta foi bem-sucedida (status 200)
      if (response && response.status === 200) {
        alert("Contato salvo com sucesso!");
      } else {
        // Caso a API tenha retornado erro (status diferente de 200)
        console.error("Erro ao salvar contato:", response);
        alert("Ocorreu um erro ao salvar o contato. Erro: " + response.status);
      }
    } catch (error) {
      // Se a API lançar uma exceção, ela será capturada aqui
      console.error("Erro ao enviar o contato:", error);
      alert("Ocorreu um erro ao salvar o contato.");
    }
  };
  

  
  const adicionarEndereco = () => {
    setcadEndereco(new CadEnderecoModel());
    setNewEndereco(new EnderecoModel());
    setnumend("");
    setnumcomp("");
    setAbrirNovoEnd(true);
  };
  const buscaCEP = async () => {
    const cep = newendereco.cep;
    showNotification("Buscando CEP", "warning");
    setNewEndereco(await getCEP(cep));
  };



  const handleDeleteEndereco = async (idEndereco: number) => {
    console.log("Tentando excluir o endereço com id:", idEndereco);  // Log para verificar o ID
    try {
      const resultado = await deleteEndereco(idEndereco.toString());  // Exclui o endereço
      console.log("Resultado da exclusão:", resultado);  // Verifica o que foi retornado
  
      if (resultado === "Sucesso" || resultado?.includes("Sucesso")) {
        // Atualiza o estado removendo o endereço excluído da lista
        setCadastro((prevState) => ({
          ...prevState,
          enderecos: prevState.enderecos.filter(
            (endereco) => endereco.cod_cad_endereco !== idEndereco
          ),
        }));
  
        showNotification("Endereço excluído com sucesso", "success");
      } else {
        // Se o resultado não for "Sucesso", mostra a mensagem de erro
        showNotification(`Erro ao excluir o endereço: ${resultado}`, "error");
      }
    } catch (error) {
      console.error("Erro na exclusão do endereço:", error);  // Log detalhado de erro
      showNotification("Erro ao excluir o endereço", "error");
    }
  };
  

  const getCadastrofull = async () => {
    await getCadastroUser(getCookie("idCad")).then((Contato) => {
      setCadastro(Contato);
      setcadCadastro(
        new CadCadastroModel(
          Contato.id_cadastro,
          Contato.status,
          Contato.tipo_pessoa,
          Contato.nome,
          Contato.fantasia,
          Contato.estrangeiro,
          Contato.data_nascimento
        )
      );
    });

    setPassoAtivo(0);
    setAbrirModalEditar(true);
  };




  const PostNewEndereco = async () => {
    console.log("Tipo de Endereço na submissão:", cadendereco.tipo_endereco);
  
    try {
      // Verifica se o número de endereços já cadastrados é menor que 5
      if (
        !newendereco.cep ||
        !newendereco.logradouro ||
        !newendereco.bairro ||
        !newendereco.cidade ||
        !newendereco.uf ||
        !numend // Verifica se o número foi preenchido
      ) {
        showNotification("Preencha todos os campos obrigatórios", "error");
        return;
      }
  
      // AQUI: Usando o tipo de endereço selecionado
      const enderecoParaSalvar = new CadEnderecoModel(
        0,  
        cadcadastro.id_cadastro, 
        cadendereco.tipo_endereco,  // Usando o tipo de endereço correto
        Number(newendereco.cod_logradouro), 
        numend, 
        numcomp 
      );
  
      // Enviando os dados para salvar o novo endereço
      await postEndereco(enderecoParaSalvar).then((endereco) => {
        if (endereco.status === 200) {
          showNotification("Cadastro Realizado", "success");
        } else {
          showNotification("Erro", "error");
        }
      });
  
      setAbrirModalEditar(false);
    } catch (error) {
      showNotification("Erro ao atualizar o cadastro", "error");
    }
    
    getCadastro(cadcadastro.id_cadastro);
    setAbrirNovoEnd(false);
  };
  
  
  
  const inputcadastro = (name: string, value: string) => {
    setcadCadastro((prevCadastro) => {
      const newCadastro = {
        ...prevCadastro,
        [name]: value,
      };
      return newCadastro;
    });
  };

  useEffect(() => {
    const fetchTiposContato = async () => {
      try {
        const tipos = await getTipoContato();

        if (tipos && Array.isArray(tipos)) {
          setTiposContato(tipos);

          if (tipos.length > 0) {
            setTipoSelecionado(tipos[0].cod_tipo_contato);
          }
        }
      } catch (error) {}
    };

    fetchTiposContato();
  }, []);

  useEffect(() => {

    setcadEndereco(
      (prevcadEndereco) =>
        new CadEnderecoModel(
          prevcadEndereco.cod_cad_endereco,
          cadcadastro.id_cadastro,
          prevcadEndereco.tipo_endereco,
          Number(endereco.cod_logradouro),
          numend,
          numcomp
        )
    );
  }, [numend, numcomp]);


 
  useEffect(() => {    
    setEndereco(
      new EnderecoModel(
        cadastro.enderecos?.[0]?.logradouro?.cod_logradouro?.toString() || "",
        cadastro.enderecos?.[0]?.logradouro?.cep || "",
        cadastro.enderecos?.[0]?.logradouro?.logradouro || "",
        cadastro.enderecos?.[0]?.logradouro?.bairro || "",
        cadastro.enderecos?.[0]?.logradouro?.cidade || "",
        cadastro.enderecos?.[0]?.logradouro?.uf || "",
        cadastro.enderecos?.[0]?.logradouro?.pais || ""
      )
    );   
  }, [cadastro]);


  const inputendereco = (cep: string) => {
    setNewEndereco(
      (prevEndereco) =>
        new EnderecoModel(
          prevEndereco.cod_logradouro,
          cep,
          prevEndereco.logradouro,
          prevEndereco.bairro,
          prevEndereco.cidade,
          prevEndereco.uf,
          prevEndereco.pais
        )
    );
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date && date.isValid()) {
      const dtnasc = date.toISOString();
      setcadCadastro(
        (prevCadastro) =>
          new CadCadastroModel(
            prevCadastro.id_cadastro,
            prevCadastro.status,
            prevCadastro.tipo_pessoa,
            prevCadastro.nome,
            prevCadastro.fantasia,
            prevCadastro.estrangeiro,
            dtnasc
          )
      );
    }
  };

  const atualizarCadastro = async () => {
    await putCadastro(cadcadastro);
    if (cadendereco.cod_cad_endereco === 0) {
      await postEndereco(cadendereco);
    } else {
      await putEndereco(cadendereco);
    }

    showNotification("Contato Atualizado", "success");
    setAbrirModalEditar(false);
  };

  const avancarPasso = () => {
    if (passoAtivo === passos.length - 1) {
    } else if (passoAtivo < passos.length - 1) {
      setPassoAtivo(passoAtivo + 1);
    }
  };

  const voltarPasso = () => {
    if (passoAtivo > 0) {
      setPassoAtivo(passoAtivo - 1);
    }
  };

  const sair = () => {
    logout();
  };

  const abreapp = (app: string) => {
    window.location.href = `https://dev-${app}.tanis-hub.com.br`;
  };

  const SelecionarEmpresa = (e: React.MouseEvent<HTMLButtonElement>) => {
    setCookie("idEmp", e.currentTarget.id, 12);
    setCookie("nomeempresa", e.currentTarget.textContent || "", 12);
    setEmpresa(e.currentTarget.textContent || "");
    setSelectEmp(false);
  };

  const getCadastroAssociacao = async (id: string) => {
    await getCadastroAssociacoes(id).then((cadastro) => {
      setempresas(cadastro);
      if (cadastro.length > 0) {
        setSelectEmp(true);
      } else {
        navigate("/tipodeacesso");
      }
    });
  };

  const TrocaEmpresa = () => {
    if (empresas.length === 0) {
      getCadastroAssociacao(getCookie("idCad") || "");
    }
    setSelectEmp(true);
  };

  useEffect(() => {
    setusuario(getCookie("username"));
    setEmpresa(getCookie("nomeempresa"));
    const idEmp = getCookie("idEmp");
    if (!idEmp) {
      if (empresas.length === 0) {
        getCadastroAssociacao(getCookie("idCad") || "");
      }
    } else {
      const nomeempresa = getCookie("nomeempresa");
      setEmpresa(nomeempresa);
    }
  }, []);
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: "100%",
          backgroundColor: isDarkMode ? "#000A1F" : "#f5f5f5", //TOp
        }}
      >
        <Dialog open={selectEmp} onClose={handleClose}>
          <DialogTitle sx={{ color: isDarkMode ? "#ffffff" : "#01050d" }}>
            Selecione a Empresa
          </DialogTitle>
          <DialogContent>
            Por favor, selecione uma empresa para continuar.
          </DialogContent>
          <DialogActions>
            <div className="div-emp">
              {empresas &&
                empresas.map((botao) => (
                  <Button
                    key={botao.idCadastro}
                    id={botao.idCadastro}
                    onClick={SelecionarEmpresa}
                    disabled={botao.status === "Bloqueado"}
                    fullWidth
                  >
                    {botao.status === "Bloqueado"
                      ? `${botao.nome} - Bloqueado`
                      : botao.nome}
                  </Button>
                ))}
            </div>
          </DialogActions>
        </Dialog>

        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={toggleside}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <h1 style={{ color: "primary" }}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "150px", height: "30px", marginRight: "10px" }}
            />
          </h1>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <p
              style={{
                color: isDarkMode ? "#ffffff" : "#01050d",
                marginRight: "20px",
                marginTop: "5px",
              }}
            >
              <strong>{empresa}</strong>
            </p>

            <FormGroup>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={isDarkMode}
                    onChange={toggleTheme}
                    icon={
                      <WbSunnyIcon sx={{ fontSize: 22, color: "#000A1F" }} />
                    }
                    checkedIcon={<NightsStayIcon sx={{ fontSize: 22 }} />}
                  />
                }
                label=""
              />
            </FormGroup>
            <NotificationButton />
            <Avatar
              color="primary"
              onClick={handleClick}
              style={{ marginLeft: "10px" }}
              sx={{
                bgcolor: isDarkMode ? "#f3f2f5" : "#000A1F",
                fontSize: "1rem", // Ajuste o tamanho da fonte para diminuir as iniciais
              }}
            >
              {" "}
              {usuario
                .split(" ")
                .map((word) => word[0])
                .join("")}{" "}
              {/* Pega as iniciais */}
            </Avatar>
            <Menu
              anchorEl={menuButton}
              open={Boolean(menuButton)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={getCadastrofull}>Meu Perfil</MenuItem>
              <MenuItem onClick={TrocaEmpresa}>Trocar de Empresa</MenuItem>
              <MenuItem onClick={sair}>Logout</MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="show more" color="primary">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar
        collapsed={!isOpen}
        collapsedWidth="70px"
        backgroundColor={isDarkMode ? "#000A1F" : "#f3f2f5"}
        style={{
          height: "calc(100vh - 64px)",
          position: "fixed",
          left: 0,
          top: 64,
          overflow: "auto",
        }}
      >
        <ProMenu
          menuItemStyles={{
            button: ({ level }) => {
              if (level === 0)
                return {
                  color: isDarkMode ? "#ffffff" : "#01050d",
                  backgroundColor: isDarkMode ? "#000A1F" : "#f3f2f5",
                  "&:hover": {
                    color: isDarkMode ? "#000000" : "#ffffff",
                    backgroundColor: isDarkMode ? "#ffffff" : "#8C8B8C ",
                  },
                };
            },
          }}
        >
          <ProMenuItem icon={<HomeIcon />}>Inicio</ProMenuItem>
          <ProMenuItem icon={<AppsIcon />}>Aplicativos</ProMenuItem>
          <ProMenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              if (
                getCookie("idEmp") === "45b0c3b1-cac1-4e46-9e9b-5362304ab3a3"
              ) {
                abreapp("general");
              } else {
                abreapp("admin");
              }
            }}
          >
            Configurações
          </ProMenuItem>
        </ProMenu>
      </Sidebar>

      <main
  className="dashboard"
  style={{
    marginLeft: isOpen ? 250 : 70,
    padding: "20px",
    marginTop: "64px", // Garante que o conteúdo fique abaixo do cabeçalho
    backgroundImage: `url(${isDarkMode ? bg_theme_dark : bg_theme_light})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "calc(100vh - 64px)",
    transition: "margin-left 0.3s ease",
    boxSizing: "border-box", // Garante que padding não ultrapasse o tamanho
  }}
>
  {/* Cabeçalho */}
  <div
    className="dashboard__header"
    style={{
      marginBottom: "30px",
      fontSize: "1.5rem",
      fontWeight: "bold",
      lineHeight: "1",
      color: isDarkMode ? "#f9fafa" : "#333", // Ajusta a cor do título com base no tema
      transition: "color 0.3s ease", // Transição suave da cor do título
    }}
  >
    <strong>
      {currentHour()} {usuario}, tenha um ótimo serviço hoje!
    </strong>
  </div>

  {/* Seção de aplicativos */}
  <section className="dashboard__section" style={{ marginBottom: "40px" }}>
    <h2
      style={{
        fontSize: "1 rem",
        marginBottom: "20px",
        color: isDarkMode ? "#f9fafa" : "#333", 
        transition: "color 0.3s ease", 
      }}
    >
      Meus Aplicativos
    </h2>
    <div
      className="dashboard__app-list"
      style={{
        display: "flex",
        gap: "20px",
        flexWrap: "wrap",
      }}
    >
     <Button
  className="dashboard__app-card"
  onClick={() => abreapp("contatos")}
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    height: "150px",
    background: isDarkMode
      ? "rgba(255, 255, 255, 0.1)" // Fundo transparente escuro no modo escuro
      : "rgba(255, 255, 255, 0.2)", // Fundo transparente no modo claro
    boxShadow: isDarkMode
      ? "0 6px 15px rgba(0, 0, 0, 0.5)" // Sombra mais forte no modo escuro
      : "0 6px 15px rgba(0, 0, 0, 0.1)", // Sombra mais suave no modo claro
    borderRadius: "12px", // Borda arredondada igual ao card
    border: "1px solid #fffafa", // Borda fina ao redor do botão
    cursor: "pointer",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  }}
>
  <img
    src={lg_contato}
    alt="Contatos"
    className="dashboard__app-image"
    style={{
      width: "50%", // Ajusta a largura da imagem dentro do botão
      objectFit: "contain",
      transition: "transform 0.3s ease",
    }}
  />
  <span
    style={{
      marginTop: "10px",
      fontWeight: "600",
      color: isDarkMode ? "#f9fafa" : "#333", // Cor do texto do botão de acordo com o tema
    }}
  >
    Contatos
  </span>
</Button>

    </div>
  </section>

  {/* Seção de contratação de aplicativos */}
  <section className="dashboard__section">
    <h2
      style={{
        fontSize: "1rem",
        marginBottom: "20px",
        color: isDarkMode ? "#f9fafa" : "#333", 
        transition: "color 0.3s ease", 
      }}
    >
      Contrate mais aplicativos
    </h2>
  </section>


        {/* Utilize o CardApp aqui */}
        <CardApp isDarkMode={isDarkMode} />
      </main>

      <Modal open={abrirModalEditar} onClose={() => setAbrirModalEditar(false)}>
        <Box
          sx={{
            width: "90vw",
            maxWidth: 900,
            maxHeight: 800,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            margin: "auto",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            mt: 5,
          }}
        >
          {/* Stepper */}
          <Stepper
            activeStep={passoAtivo}
            alternativeLabel
            sx={{
              marginBottom: 8,
              paddingX: 2,
              "& .MuiStepIcon-root": { fontSize: "2rem" },
              "& .MuiStepLabel-root": { fontWeight: "bold", fontSize: "1rem" },
            }}
          >
            {passos.map((label, index) => (
              <Step key={index}>
                <StepLabel sx={{ fontSize: "1.1rem" }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <form onSubmit={(e) => e.preventDefault()}>
            <Grid
              container
              spacing={3}
              sx={{
                marginBottom: "20px",
                marginLeft: "1px",
                paddingRight: { xs: "0", sm: "16px" },
              }}
            >
              {/* Etapa Dados Pessoais */}
              {passoAtivo === 0 && (
                <>
                  <Grid container spacing={3}>
                    {/* Nome ou Razão Social */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={
                          cadcadastro.tipo_pessoa === "Fisica"
                            ? "Nome"
                            : "Razão Social"
                        }
                        fullWidth
                        value={cadcadastro.nome}
                        onChange={(e) => inputcadastro("nome", e.target.value)}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "12px",
                            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.08)",
                            "&:hover": {
                              boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            fontWeight: "600",
                            fontSize: "1.1rem",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {cadcadastro.tipo_pessoa !== "Fisica" && (
                        <TextField
                          label={"Nome Fantasia"}
                          fullWidth
                          value={cadcadastro.fantasia}
                          onChange={(e) =>
                            inputcadastro("fantasia", e.target.value)
                          }
                          sx={{
                            "& .MuiInputBase-root": {
                              borderRadius: "12px",
                              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.08)",
                              "&:hover": {
                                boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              fontWeight: "600",
                              fontSize: "1.1rem",
                            },
                          }}
                        />
                      )}
                    </Grid>
                    {/* CPF ou CNPJ */}
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        mask={
                          cadcadastro.tipo_pessoa === "Fisica"
                            ? "999.999.999-99"
                            : "99.999.999/9999-99"
                        }
                        maskChar={null}
                        value={cadastro.documentos?.[0]?.ds_documento || ""}
                        onChange={(e) =>
                          inputcadastro("ds_documento", e.target.value)
                        }
                      >
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            label={
                              cadcadastro.tipo_pessoa === "Fisica"
                                ? "CPF"
                                : "CNPJ"
                            }
                            fullWidth
                            value={cadastro.documentos?.[0]?.ds_documento || ""}
                            sx={{
                              "& .MuiInputBase-root": {
                                borderRadius: "12px",
                                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.08)",
                                "&:hover": {
                                  boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                fontWeight: "600",
                                fontSize: "1.1rem",
                              },
                            }}
                          />
                        )}
                      </InputMask>
                    </Grid>

                    <Grid item xs={8} sm={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br"
                      >
                        <DatePicker
                          label={
                            cadcadastro.tipo_pessoa === "Fisica"
                              ? "Data Nascimento"
                              : "Data Abertura"
                          }
                          value={dayjs(cadcadastro.nascimento)}
                          onChange={(newdate) => handleDateChange(newdate)}
                          sx={{
                            "& .MuiInputBase-root": {
                              borderRadius: "12px",
                              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.08)",
                              "&:hover": {
                                boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              fontWeight: "600",
                              fontSize: "1.1rem",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* Tipo de Pessoa */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel
                          sx={{ fontWeight: "600", fontSize: "1.1rem" }}
                        >
                          Tipo de Pessoa
                        </FormLabel>
                        <RadioGroup
                          name="tipo_pessoa"
                          value={cadcadastro.tipo_pessoa}
                          onChange={(e) =>
                            inputcadastro("tipo_pessoa", e.target.value)
                          }
                        >
                          <FormControlLabel
                            value="Fisica"
                            control={<Radio />}
                            label="Pessoa Física"
                          />
                          <FormControlLabel
                            value="Juridica"
                            control={<Radio />}
                            label="Pessoa Jurídica"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {/* Estrangeiro */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cadastro.estrangeiro === "S"}
                            onChange={(e) =>
                              inputcadastro("estrangeiro", e.target.value)
                            }
                          />
                        }
                        label="Estrangeiro"
                        sx={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          marginLeft: "16px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {passoAtivo === 1 && (
                <>
                  <form>
                    <Grid container spacing={3}>
                      {/* Botão "Novo Endereço" */}
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={adicionarEndereco}
                          style={{ marginBottom: "16px" }}
                        >
                          Novo Endereço
                        </Button>
                      </Grid>

                      {/* Exibir endereços já cadastrados */}
                      {cadastro.enderecos &&
                        cadastro.enderecos.length > 0 &&
                        cadastro.enderecos.map((endereco, index) => (
                          <Accordion
                            key={index}
                            expanded={expandedIndex === index}
                            onChange={() =>
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }
                            sx={{ marginBottom: "16px" }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${index}-content`}
                              id={`panel-${index}-header`}
                            >
                              <Typography variant="h6">
                                 Endereço: {endereco.tipo_endereco}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                {/* Campos para exibir endereço existente */}
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Tipo de Endereço"
                                    value={endereco.tipo_endereco}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="CEP"
                                    value={endereco.logradouro.cep}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label="Rua"
                                    value={endereco.logradouro.logradouro || ""}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Número"
                                    value={endereco.numero_endereco || ""}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Complemento"
                                    value={endereco.numero_complemento || ""}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Bairro"
                                    value={endereco.logradouro.bairro || ""}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Cidade"
                                    value={endereco.logradouro.cidade || ""}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="UF"
                                    value={endereco.logradouro.uf || ""}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                  />
                                </Grid>

                                {/* Botão de Exclusão */}
                                <Grid item xs={12}>
                                <Button
  variant="contained"
  color="error"
  onClick={() => {
    console.log("Excluir endereço com id:", endereco.cod_cad_endereco);  // Verifique o ID aqui
    handleDeleteEndereco(endereco.cod_cad_endereco);
  }} // Chama a função de exclusão
>
  Excluir Endereço
</Button>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </Grid>
                  </form>
                </>
              )}

              {/* Etapa Contato */}
              {passoAtivo === 2 && (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: 3,
                      borderRadius: "10px",
                      backgroundColor: (theme) =>
                        theme.palette.background.paper,
                    }}
                  >
                    {/* Título da seção */}
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: 2,
                          color: (theme) => theme.palette.text.primary,
                        }}
                      >
                        Adicione formas de Contato
                      </Typography>
                    </Grid>

                    {contato.length === 0 && (
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="sl-tp" color="primary">
                              Tipo de Contato
                            </InputLabel>
                            <Select
                              id="sl-tp"
                              value={tipoSelecionado}
                              onChange={handleSelectChange}
                              fullWidth
                              variant="filled"
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.background.paper,
                                borderRadius: "8px",
                                "& .MuiFilledInput-root": {
                                  backgroundColor: (theme) =>
                                    theme.palette.background.paper,
                                },
                                "& .MuiSelect-select": {
                                  padding: "10px 12px",
                                },
                              }}
                            >
                              {/* Renderizar os tipos de contato */}
                              {tiposContato.map((tipo) => (
                                <MenuItem
                                  key={tipo.cod_tipo_contato}
                                  value={tipo.cod_tipo_contato}
                                >
                                  {tipo.ds_tipo_contato}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            value={contato}
                            onChange={(e) => setContato(e.target.value)}
                            variant="outlined"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.background.paper, // Usando o background do tema
                              borderRadius: "8px",
                              "& .MuiFilledInput-root": {
                                backgroundColor: (theme) =>
                                  theme.palette.background.paper, // Garantir que o fundo também se ajuste dentro do select
                              },
                              "& .MuiSelect-select": {
                                padding: "10px 12px", // Para garantir que o Select tenha um bom espaçamento e não fique colado
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <IconButton color="primary">
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}

                    {/* Adicionar novo contato */}
                    {contato.length > 0 && (
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={4}>
                          <Select
                            value={tipoSelecionado}
                            onChange={handleSelectChange}
                            fullWidth
                            displayEmpty
                            variant="outlined"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.background.paper, // Usando o background do tema
                              borderRadius: "8px",
                              "& .MuiFilledInput-root": {
                                backgroundColor: (theme) =>
                                  theme.palette.background.paper, // Garantir que o fundo também se ajuste dentro do select
                              },
                              "& .MuiSelect-select": {
                                padding: "10px 12px", // Para garantir que o Select tenha um bom espaçamento e não fique colado
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              Selecione um tipo de contato
                            </MenuItem>
                            {tiposContato.map((tipo) => (
                              <MenuItem
                                key={tipo.cod_tipo_contato}
                                value={tipo.cod_tipo_contato}
                              >
                                {tipo.ds_tipo_contato}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            value={valorContato} // Valor do contato a ser inserido
                            onChange={(e) => setValorContato(e.target.value)} // Atualizar o valor do contato
                            placeholder="Digite o contato"
                            variant="outlined"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.background.paper, // Usando o background do tema
                              borderRadius: "8px",
                              "& .MuiFilledInput-root": {
                                backgroundColor: (theme) =>
                                  theme.palette.background.paper, // Garantir que o fundo também se ajuste dentro do select
                              },
                              "& .MuiSelect-select": {
                                padding: "10px 12px", // Para garantir que o Select tenha um bom espaçamento e não fique colado
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={adicionarContato}
                            startIcon={<AddCircleOutlineIcon />} // Coloca o ícone antes do texto
                          >
                            Salvar Contato
                          </Button>
                        </Grid>
                      </Grid>
                    )}

                    {/* Lista de contatos adicionados */}
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold", marginBottom: "16px" }}
                      >
                        Contatos Adicionados
                      </Typography>
                      <ul>
                        {cadastro.contatos.map((cadastro, index) => (
                          <Grid
                            container
                            spacing={3}
                            key={index}
                            sx={{
                              padding: "4px",
                              borderRadius: "8px",
                            }}
                          >
                            <Grid item xs={12} sm={8}>
                              <TextField
                                value={cadastro.ds_contato}
                                fullWidth
                                aria-readonly
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {(cadastro.tipo_contato
                                        .cod_tipo_contato === 1 ||
                                        cadastro.tipo_contato
                                          .cod_tipo_contato === 3) && (
                                        <PhoneIcon />
                                      )}
                                      {cadastro.tipo_contato
                                        .cod_tipo_contato === 2 && (
                                        <EmailIcon />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <IconButton
                                color="error"
                                onClick={() => handleDeleteContato(cadastro.cod_contato)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </ul>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Etapa Resumo */}
              {passoAtivo === 3 && (
                <>
                  <Grid container spacing={3}>
                    {/* Seção de Telefones */}
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Empresas Conectadas
                      </Typography>
                      <Button
                        onClick={() => navigate("/tipodeacesso")}
                        sx={{
                          backgroundColor: "#1976D2",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#1565C0",
                            transform: "scale(1.05)",
                          },
                          padding: "8px 20px",
                          borderRadius: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Conectar Nova Empresa
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {cadastro.associacoes.map((associacao, index) => (
                        <TextField
                          key={index}
                          label="Razão Social"
                          value={associacao.nome}
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              borderRadius: "8px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              "&:hover": {
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                mt: 3,
              }}
            >
              {/* Botão Voltar */}
              {passoAtivo > 0 && (
                <Button
                  onClick={voltarPasso}
                  sx={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1565C0",
                      transform: "scale(1.05)",
                    },
                    padding: "8px 20px",
                    borderRadius: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Voltar
                </Button>
              )}

              {/* Botão Avançar */}
              {passoAtivo < passos.length - 1 ? (
                <Button
                  onClick={avancarPasso}
                  variant="contained"
                  sx={{
                    backgroundColor: "#32ADE6",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#0D47A1",
                      transform: "scale(1.05)",
                    },
                    padding: "8px 20px",
                    borderRadius: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Avançar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={atualizarCadastro}
                  sx={{
                    backgroundColor: "#32ADE6",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#0D47A1",
                      transform: "scale(1.05)",
                    },
                    padding: "8px 20px",
                    borderRadius: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Finalizar
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal open={abrirnovoend} onClose={() => setAbrirNovoEnd(false)}>
        <Box
          sx={{
            width: "90vw",
            maxWidth: 900,
            maxHeight: 800,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            margin: "auto",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            mt: 5,
          }}
        >
          <Grid container spacing={3}>
            {/* Formulário para adicionar um novo endereço */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel sx={{ fontWeight: "bold" }}>
                  Tipo de Endereço
                </InputLabel>
                <Select
                  value={cadendereco.tipo_endereco || ""}
                  label="Tipo de Endereço"
                  onChange={(e) =>
                    setcadEndereco({
                      ...cadendereco,
                      tipo_endereco: e.target.value,
                    })
                  }
                  sx={{
                    "& .MuiSelect-root": {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                      },
                    },
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <MenuItem value="Principal"> Principal </MenuItem>
                  <MenuItem value="Faturamento"> Faturamento </MenuItem>
                  <MenuItem value="Entrega"> Entrega </MenuItem>                  
                </Select>
              </FormControl>
            </Grid>
            

            {/* Formulário para CEP e Campos adicionais */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="cep"
                value={newendereco.cep}
                onChange={(e) => inputendereco(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Previne o envio do formulário
                    buscaCEP(); // Chama a função de busca
                  }
                }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button variant="contained" color="primary" onClick={buscaCEP}>
                Buscar
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Rua"
                value={newendereco.logradouro || ""}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Número"
                value={numend}
                onChange={(e) => setnumend(e.target.value)}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltip title="Digite o complemento">
                <TextField
                  label="Complemento"
                  value={numcomp}
                  onChange={(e) => setnumcomp(e.target.value)}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltip title="Digite o bairro">
                <TextField
                  label="Bairro"
                  value={newendereco.bairro}
                  onChange={(e) =>
                    setEndereco({
                      ...newendereco,
                      bairro: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title="Digite a cidade">
                <TextField
                  label="Cidade"
                  value={newendereco.cidade}
                  onChange={(e) =>
                    setNewEndereco({
                      ...newendereco,
                      cidade: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title="Digite o uf">
                <TextField
                  label="UF"
                  value={newendereco.uf}
                  onChange={(e) =>
                    setNewEndereco({
                      ...newendereco,
                      uf: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Tooltip>
            </Grid>

            {/* Botão para adicionar o novo endereço */}
            <Grid item xs={12}>
              <Tooltip title="Clique para adicionar um novo endereço">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={PostNewEndereco}
                >
                  Adicionar Endereço
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Home;
